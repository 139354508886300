.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: -1rem 0 0.5rem -0.5rem;
  white-space: nowrap;
  width: 10rem;
}

.radio-item input[type='radio'] {
  cursor: pointer;
  display: none;
}

.radio-item label {
  cursor: pointer;
  color: $body3;
  font-weight: 600;
}

.radio-item label:before {
  content: ' ';
  display: inline-block;
  position: relative;
  background-color: transparent;
  border-radius: 11px;
  border: 2px solid $ui1;
  top: 5px;
  width: 20px;
  height: 20px;
  margin: 0 5px 0 0;
}

.radio-item label:after {
  content: ' ';
  display: block;
  position: absolute;
  background: $success1;
  border-radius: 11px;
  top: 9px;
  left: 10px;
  width: 12px;
  height: 12px;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.radio-item input[type='radio']:checked + label:before {
  border-color: $success1;
}

.radio-item input[type='radio']:checked + label:after {
  opacity: 1;
}

.radio-item input[type='radio']:disabled + label {
  opacity: .25;
  cursor: default;
}