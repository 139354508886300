.overflow {
  height: 3rem;
  width: 5rem;
  margin: 0 0.6rem 0 auto;
  background-position: 49px center;
  background-size: 24px 24px;
  position: relative;
  cursor: pointer;
  &:after {
    content: '';
    background: url('../assets/overflow.svg') no-repeat center right;
    display: block;
    height: 3rem;
    width: 100%;
    position: absolute;
    right: 1.4rem;
  }
  ul {
    list-style: none;
    li {
      width: 183px;
      a,
      &.actionable {
        font-size: 1.4rem;
        font-weight: 600;
        height: 42px;
        padding-left: 2rem;
        text-align: left;
        display: flex;
        align-items: center;
      }
    }
  }
  .options {
    position: absolute;
    display: none;
    width: 180px;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px $ui7;
    top: 40px;
    right: 15px;
    z-index: 2;
  }

  .options.fadeIn {
    display: block;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0);
    transform-origin: top right;
  }
  to {
    opacity: 1;
    transform: scale(1);
    transform-origin: top right;
  }
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 200ms;
  animation-timing-function: ease-in;
}
