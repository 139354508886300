button, .action {
  min-width: 100px;
  height: 40px;
  background: $primary4;
  border-radius: .2rem;
  font-size: 1.6rem;
  font-weight: 600;
  color: $light;
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  text-decoration: none;
  padding: .25rem 2rem;
  margin-right: 2rem;
  cursor: pointer;
}

.add {
  color: $primary4;
  //padding-right: 4em;
  background: transparent;
  background: transparent url(../assets/create.svg) right .5rem center no-repeat;
  margin: 0;
  padding: .25rem 3rem .25rem 1rem;
}

.create {
  margin-right: 0;
}

button:active, .action:active {
  opacity: .75;
  outline: 0;
}

button:disabled, .action:disabled {
  opacity: .37;
}

.secondary {
  background: transparent;
  border: solid 2px $secondary;
  color: $secondary;
}

.secondary:active {
  opacity: .75;
  outline: 0;
}

.secondary:disabled {
  opacity: .37;  
}

.tertiary {
  background: $light;
  border: solid 2px $primary2;
  color: $primary2;
}

.tertiary:active {
  opacity: .75;
  outline: 0;
}

.tertiary:disabled {
  opacity: .37;  
}

button.saving {
  position: relative;
  &::after {
    position: absolute;
    width: 3rem;
    height: 3rem;
    transform: translateX(120%) rotate(0deg);
    animation: spin-120x 1500ms infinite linear;
    content: '';
    right: 0;
    top: 0.5rem;
    background: url('../assets/spinner.svg') no-repeat center center;
    background-size: contain;
  }
  @keyframes spin-120x {
    100% {
      transform: translateX(120%) rotate(360deg);
    }
  }
}
