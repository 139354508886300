$grey-outline: rgba(146, 147, 150, 0.7);

.conductor-builder {
  grid-column: 1 / 6;
  grid-template-columns: 1fr 13rem;
  grid-column-gap: 3rem;
  border-radius: 2px;
  border: 1px solid #c4c6cc;
  margin-bottom: 2rem;

  .conductor-builder__selected ul,
  .color-picker ul {
    display: flex;
    flex-flow: row wrap;
  }
}

.size-warning {
  font-size: 12px;
  font-weight: bold;
  background: url(../assets/information.svg) left center no-repeat;
  background-size: 22px 22px;
  padding-left: 2.4rem;
}

.conductor-builder__selected {
  padding: 1.5rem;
  position: relative;

  li {
    position: relative;
  }

  .size-warning {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1.5rem 1rem 1.5rem 2.8rem;
  }

  .notch {
    width: 16px;
    height: 16px;
    transform: rotate(45deg);
    background-color: #fbfbfb;
    border-top: 1px solid #d3d5db;
    border-left: 1px solid #d3d5db;
    position: absolute;
    bottom: -23px;
    left: calc(50% - 1.5rem);
  }
}

.conductor-builder__size-color-picker {
  background-color: #fbfbfb;
  border-top: 1px solid #d3d5db;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1.5rem 2rem;

  .size-picker {
    padding-right: 2rem;
    border-right: 1px solid #b3bac9;
    grid-column: 1 / 2;
  }

  .color-picker {
    padding-left: 2rem;
    grid-column: 2 / 4;
  }
}

.conductor-swatch {
  display: flex;
  flex-direction: column;
  margin: 1rem 2.2rem 0 1rem;
  align-items: center;

  label {
    color: #68727d;
    margin-top: 0.5rem;
    margin-bottom: 0;
    font-weight: 300;
    font-size: 1.1rem;
    text-align: center;
    cursor: pointer;
  }

  .size-warning-icon {
    position: absolute;
    top: -5px;
    right: -6px;
    width: 19px;
    height: 19px;
  }

  .swatch {
    position: relative;
    background-color: $grey-outline;
    border-radius: 50%;
    cursor: pointer;
    padding: 2px;
    margin: auto;

    &.empty {
      background-color: #ffffff;
      border: thin dashed $grey-outline;
    }

    &:after {
      opacity: 0;
      visibility: hidden;
      background: url(/assets/img/checkmark.svg) no-repeat center;
      content: '';
      position: absolute;
      width: 22px;
      height: 19px;
      top: 11px;
      left: 9px;
      transition: all 0.25s ease-out;
    }

    &.selected:after {
      opacity: 1;
      visibility: visible;
    }
  }

  .swatch-color {
    appearance: none;
    cursor: pointer;
    font-size: 0;
    border-radius: 50%;
    border: 0;

    width: 3.6rem;
    height: 3.6rem;
  }
}
