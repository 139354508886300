$disabled: rgba(190, 192, 196, 0.25);

.checkbox input {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it 
  width: initial;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box - unchecked
  & + label:before {
    content: '';
    margin-right: 4px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 2px;
    border: 2px solid $ui3;
    transition: all 0.2s cubic-bezier(0.4, -0.25, 0.25, 1);
  }

  // Disabled
  &:disabled {
    & + label {
      cursor: default;
    }

    & + label:before {
      border-color: $disabled;
    }
  }

  // Box - focus
  &:focus + label:before {
    border-color: $primary4;
  }

  // Box - hover
  &:hover + label:before {
    border-color: $ui1;
  }

  // Box - checked
  &:checked + label:before {
    background: $success1;
    border-color: $success1;
  }

  // Box - checked and focused
  &:checked:focus + label:before {
    border-color: $primary4;
  }

  // Checkmark
  & + label:after {
    content: '';
    opacity: 0;
    position: absolute;
    top: 2px;
    left: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
    width: 6px;
    height: 12px;
    transform: rotate(45deg);
    transition: all 0.25s ease-out;
  }

  // Checkmark - checked
  &:checked + label:after {
    opacity: 1;
  }
}

.deselect input {
  // Box - checked
  &:checked + label:before {
    background: $ui3;
    border: $ui3;
  }
  // Deselect Icon
  & + label:after {
    top: 9px;
    left: 4px;
    transform: none;
    width: 12px;
    height: 2px;
  }
}
