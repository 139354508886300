.tooltip {
  transition: visibility 0s, opacity 200ms ease-in-out;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: 45px;
  height: 40px;
  border: 1px solid $body3;
  background: $light;
  color: $body2;
  font-size: 14px;
  padding: 12px 15px;
  border-radius: 3px;
  white-space: nowrap;
  text-decoration: none;
  text-transform: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  z-index: 2;
  &::after {
    position: absolute;
    content: '';
    top: 31px;
    left: 10px;
    width: 16px;
    height: 16px;
    transform: rotate(45deg);
    background-color: $light;
    border-bottom: 1px solid $body3;
    border-right: 1px solid $body3;
  }
  &.isHovered {
    opacity: 1;
    visibility: visible;
  }
  &.right {
    right: 0;
    &::after {
      right: 10px;
      left: auto;
    }
  }
}
