#root > div {
  position: relative;
}

#modal-background {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $ui1;
  opacity: 0.64;
}

#modal {
  position: fixed;
  background-color: white;
  z-index: 4;
  width: 40rem;
  top: 30vh;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 0.2rem;

  h1 {
    padding: 2rem 3rem;
    border-bottom: 1px solid #b0b1b5;
  }

  .modal-text {
    text-align: center;
    padding: 2.1rem 8rem;
  }

  .alert-icon {
    background: url(../assets/alert-red.svg) center/contain no-repeat;
    height: 2.7rem;
    width: 2.3rem;
    margin: 0 auto;
  }

  .button-container {
    display: flex;
    align-items: center;
    Button {
      width: 50%;
      background-color: transparent;
      padding: 0;
      margin: 0;
      border-radius: 0;
      border-top: 1px solid $ui2;
      &.cancel {
        color: $secondary;
        border-right: 1px solid $ui2;
      }
      &.confirm {
        color: $error1;
      }
    }
  }

  /* START IE 11 fallback styles */
  .modal-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .form-field {
      width: 50%;
      padding-right: 2rem;
    }
    .dropdown {
      width: 100%;
    }
  }
  @supports (display:grid) {
    .modal-text {
      display: grid;
      .form-field {
        width: initial;
        padding-right: 0;
      }
      .dropdown {
        width: initial;
      }
    }
  }
  /* END IE 11 fallback styles */
}
