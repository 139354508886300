$primary: #3e5d77;
$primary2: #5685c1;
$primary3: #82a8d8;
$primary4: #376bad;

/* secondary (cancel) button color */
$secondary: #9c7563;

$light: #ffffff;

//UI Elements (Greys)
$ui1: #838487;
$ui2: #b0b1b5;
$ui3: #c4c6cc;
$ui4: #dadce0;
$ui5: #e6e7e8;
$ui7: #a6acba;

// background
$background1: #e8ebed;

//Body text
$body1: #1a1818;
$body2: #3a4147;
$body3: #68727d;
$body4: #90979e;
$body5: #4d5863;

$error1: #f33a0e;
$error2: #ff6540;
$error3: #ff8163;

$success1: #009434;
$success2: #12b149;
$success3: #60d188;

$selected: #00ddff;
$warning: #ffd641;

$min-width: 76rem;
$max-width: 128rem;
$header-height: 12.7rem;
$footer-height: 8rem;
$gutter: 4%;
$configure-extra-space: 12rem;

// borders for configure screen
$feeder-border: 1px solid $ui3;
$header-border: 1px solid $ui4;

