.job-reel-form {
  display: grid;
  max-width: 56rem;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;

  .form-field:first-of-type,
  .restrictions,
  .action-buttons {
    grid-column: 1 / 4;
  }

  .job-type {
    display: flex;
    grid-column: 1 / 2;
  }
  .checkbox-toggle {
    grid-column: 1 / 3;
    min-height: 87.5px;
  }

  .job-settings-fields {
    grid-column: 1 / 4;

    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;

    .form-field {
      grid-column: auto;
    }
  }

  /* START IE 11 fallback styles */
  .job-settings-fields > * {
    float: left;
    padding-right: 1rem;
    width: 33%;
  }

  .checkbox-toggle {
    clear: both;
  }

  .job-type,
  .job-settings-fields {
    padding: 2rem 0;
  }

  @supports (display:grid) {
    .job-settings-fields > * {
      width: auto;
    }

    .job-type,
    .job-settings-fields {
      padding: 0;
    }
  }
  /* END IE 11 fallback styles */
}
