select {
  height: 40px;
  border-radius: 2px;
  background-color: #ffffff;
  border: solid 1px $ui7;
  padding: 0 4rem 0 1.6rem;
  width: 100%;
  font-size: 14px;
  appearance: none;
  background: url(/assets/img/chevron-down.svg) no-repeat right 16px center;
}

/*ie 11*/
select::-ms-expand {
  display: none;
}

select:focus {
  outline: 0;
  border: solid 1px #376bad;
}

/**
 * Styles related to the custom select Dropdown component
 */

.dropdown {
  position: relative;
  text-align: left;
  cursor: pointer;

  &.nested {
    position: static;
    margin-bottom: 0;
  }

  .dropdown__button {
    height: 40px;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px $ui7;
    padding: 0.8rem 4rem 0 1.6rem;
    width: 100%;
    font-size: 14px;
    appearance: none;
    background: url(/assets/img/chevron-down.svg) no-repeat right 16px center;

    overflow: hidden;
    line-height: 1.8;

    &.disabled {
      cursor: default;
      color: rgba(0, 0, 0, 0.247);
    }

    &.nested {
      border: 0;
      padding-top: 4px;
      padding-bottom: 4px;
      height: initial;
      background: url(/assets/img/chevron-right-blue.svg) no-repeat right 16px center;
    }
  }

  .dropdown__menu-wrapper {
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 50;
  }

  .dropdown__menu {
    background-color: #ffffff;
    border: solid 1px $ui7;
    border-radius: 2px;
    width: 100%;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    padding: 8px 0;
    max-height: 250px;
    overflow-x: hidden;
    overflow-y: auto;

    &.nested {
      position: absolute;
      left: 100%;
      width: initial;
    }
  }

  .dropdown__menu-item {
    font-size: 14px;
    padding: 4px 16px;

    &.nested {
      padding: 0;
    }

    &.selected {
      color: #ffffff;
      background-color: $primary4;

      .dropdown__button.nested {
        background: url(/assets/img/chevron-right-white.svg) no-repeat right 16px center;
      }
    }

    &:focus,
    &:hover {
      text-decoration: none;
      color: #ffffff;
      background-color: $primary3;

      .dropdown__button.nested {
        background: url(/assets/img/chevron-right-white.svg) no-repeat right 16px center;
      }
    }
  }

  /**
   * Swatches displayed in a dropdown
   */
  .swatch__container {
    display: flex;

    .swatch {
      display: flex;
      background-color: white;
      border-radius: 50%;
      padding: 1px;
      margin: 0px 3px;

      &.outline {
        background-color: $ui3;
      }
    }

    .swatch-color {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
    }
  }
}
