.expand {
  &.down {
    background: url('../assets/chevron-down.svg') no-repeat center center;
  }
  &.up {
    background: url('../assets/chevron-up.svg') no-repeat center center;
  }

  cursor: pointer;
  height: 52px;
  margin: 0 0 0 auto;
  width: 4rem;
  background-position: 1rem center;
  background-size: 5px 19px;
}
