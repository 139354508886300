$table-border: #d3d5db;

.table {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;

  tr {
    height: 5.2rem;
    position: relative;
  }

  tr.link {
    cursor: pointer;
  }

  tr.duplicate {
    animation: green-fade 2000ms ease-in 200ms;
  }

  td {
    position: relative;
    border-bottom: 1px solid $table-border;

    &:first-child {
      border-left: 1px solid $table-border;
    }

    &:last-child {
      border-right: 1px solid $table-border;
    }
  }

  td.overflow-cell {
    padding: 0;
  }

  td.expanded-item {
    padding: 0;
    background-color: #f5f6f7;
    line-height: normal;
    height: auto;
    min-height: 8rem;

    .expanded-colors {
      padding: 2rem 2rem 2.5rem 2rem;
      display: flex;
      flex-wrap: wrap;

      .selected-color {
        margin-bottom: 1rem;
      }
    }

    .expanded-header {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid $table-border;
      padding: 2rem;

      & > span:not(.simpull-text) {
        margin-right: 4rem;
      }
    }

    .notch {
      width: 16px;
      height: 16px;
      transform: rotate(45deg);
      background-color: #f5f6f7;
      border-top: 1px solid $table-border;
      border-left: 1px solid $table-border;
      position: absolute;
      right: 13px;
      top: -9px;
    }

    ul.mv-expanded-details {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1px;

      margin: 0;
      padding: 0;
      list-style: none;
      height: auto;
      min-height: 8rem;

      li {
        outline: 1px solid $table-border;
        padding: 2rem;
        text-align: left;

        h3 {
          text-transform: uppercase;
          font-weight: 300;
          font-size: 18px;
          color: $body2;
        }

        p {
          font-size: 16px;
          font-weight: normal;
        }
      }

      /* START IE 11 fallback styles */
      li {
        width: 33%;
        outline: none;
        display: inline-block;
        border-right: 1px solid $table-border;
        border-bottom: 1px solid $table-border;
      }
      li:nth-child(3),
      li:nth-child(6) {
        border-right: none;
      }
      li:nth-child(7),
      li:nth-child(8),
      li:nth-child(9) {
        border-bottom: none;
      }
      @supports (display:grid) {
        li {
          width: initial;
          outline: 1px solid $table-border;
          border: none;
          display: block;
        }
      }
      /* END IE 11 fallback styles */
    }

    .fixed-disclaimer {
      background-color: $light;
      border-top: 1px solid #d3d5db;
      text-align: left;
      font-weight: normal;
      padding: 0.5rem 2rem;
      font-size: 11px;
      color: $body3;
    }
  }

  td,
  th {
    padding: 1rem 0 1rem 1.3rem;
    text-align: left;
    font-weight: 600;
    color: $body2;
    font-size: 1.4rem;
    line-height: 1.25;
    border-bottom: 1px solid $table-border;

    &:last-child {
      text-align: right;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  thead th {
    color: $body2;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;

    &:first-child {
      width: 30rem;
    }
    &:nth-child(2) {
      width: 10.5rem;
    }
    &:nth-child(3) {
      width: 10.5rem;
    }
  }

  .expand-cell {
    width: 4rem;
    padding: 0;
  }

  .icon-cell {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .expand, button {
      margin: 0;
    }
    .icon-button {
      align-self: center;
    }
  }

  &.many-columns {
    thead th {
      &:nth-child(4) {
        width: 7rem;
      }
      &:nth-child(5) {
        width: 7rem;
      }
      &:nth-child(6) {
        width: 6rem;
      }
    }
  }

  tfoot {
    background-color: $background1;
    border: 1px solid $ui2;

    td {
      white-space: nowrap;
      overflow: visible;
      border-bottom: 1px solid $ui2;
      color: $body3;
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: 600;

      &:nth-of-type(1) {
        border-left: 1px solid $ui2;
      }

      &:nth-of-type(2) {
        padding-left: 1rem;
      }

      &:nth-of-type(7) {
        border-right: 1px solid $ui2;
      }
    }

    .circuit-count {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 2.5rem;
      width: 3.6rem;
      border-radius: 2.25rem;
      background-color: $success1;
      color: $light;
      margin-right: 1rem;
    }

    .footer-icons {
      display: flex;
      justify-content: flex-end;
      padding: 1rem 2rem;

      button {
        margin-right: 0;
      }
    }
  }
}

.table.sortable {
  td,
  th {
    &.checkbox,
    &.checkbox-cell {
      border-right: 0;
      width: 56px;
      padding: 0 1.6rem;
    }
  }

  thead th {
    position: relative;
    height: 4.7rem;
    border: 1px solid $table-border;
    border-left: 0;
    color: $body3;

    &:first-child {
      border-left: 1px solid $table-border;
    }

    &.sortable {
      cursor: pointer;
    }

    &.from,
    &.to,
    &.size {
      width: 16%;
    }

    &.length {
      width: 19%;
    }

    &.reel,
    &.expand-menu {
      width: 30%;
    }

    &.sort-selected {
      background-color: #f5f6f7;
    }

    .sort-icon {
      position: absolute;
      top: 1.1rem;
      right: 1rem;
      transition: transform 0.25s ease-out;
    }

    .sort-icon-asc {
      transform: rotate(180deg);
    }

    .sort-icon-asc,
    .sort-icon-desc {
      width: 20px;
      height: 20px;
      top: 1.3rem;
    }
  }
}

@media all and (max-width: 70em) {
  .table.many-columns thead tr th {
    &:first-child {
      width: 24rem;
    }

    &:nth-child(2),
    &:nth-child(3) {
      width: 8rem;
    }
  }

  .table tr td:nth-child(2),
  .table tr td:nth-child(3), {
    font-size: 12px;
  }
}

