.conductor-detail {
  display: flex;
  align-items: center;

  .dot {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid $ui1;
  }
  .conductor-label {
    margin: .3rem 3rem .3rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
