@import 'variables';
@import 'button';
@import 'checkbox';
@import 'input';
@import 'radio';
@import 'conductor-builder';
@import 'conductor-detail';
@import 'expand';
@import 'icon-button';
@import 'modal';
@import 'overflow';
@import 'select';
@import 'toggle';
@import 'table';
@import 'tooltip';

@import 'job-reel-form';

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}
body,
html,
#root {
  min-height: 100vh;
}
html {
  font-size: 62.5%;
  text-size-adjust: 100%;
}
body {
  color: $body2;
  font-size: 1.6rem;
  font-family: 'Open Sans', system-ui, 'Segoe-UI', -apple-system, Roboto,
    sans-serif;
}

a {
  text-decoration: none;
  color: $primary;
}

a:hover,
a:active,
li.actionable:hover {
  opacity: 0.37;
}

p {
  font-size: 14px;
}

#root {
  min-width: $min-width;
}

//what is the grid way of doing this?
.container {
  width: 100%;
  max-width: $max-width;
  margin: 0 auto;
  padding: 0 $gutter;
}

header {
  background: $primary;
  height: $header-height;
  color: $light;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  flex-direction: column;
}
header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header a {
  color: $light;
  text-decoration: none;
}
header h1 a:hover {
  opacity: 1;
}
header .account-link {
  padding: 1rem 0rem 1rem 2.2rem;
  background: transparent url(../assets/gear.svg) left center no-repeat;
}
h1 {
  font-size: 2.8rem;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.logo {
  width: 182px;
  height: 44px;
  margin-right: 2rem;
}
h1 .heading-title {
  padding-left: 2rem;
  border-left: 1px solid currentColor;
  line-height: 0.75;
  position: relative;

  span {
    font-size: 0.5em;
    transform: translateY(-100%);
    display: inline-block;
  }

  &::after {
    content: 'Web App';
    font-size: 1rem;
    position: absolute;
    bottom: 0;
    right: 1.6rem;
    transform: translateY(200%);
  }
}

h2 {
  font-size: 28px;
  font-weight: 300;
  line-height: 1.25;
  color: $body2;
}

.page-title {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 0 4rem 0;
  &.lb {
    border-bottom: 1px solid $ui3;
    margin-bottom: 3rem;
  }
  dl {
    margin-top: 2rem;
  }
  &.secondary-title {
    padding-top: 1.4rem;
    padding-bottom: 3.7rem;
  }
}

.action-heading {
  padding: 3rem 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.action-heading + .page-title {
  padding-top: 0rem;
}
.project-home {
  border-bottom: 1px solid $ui3;
}

main {
  display: block;
  min-height: calc(100vh - #{$header-height} - #{$footer-height});
  padding: 0 0 3rem 0;
  &.login {
    min-height: calc(100vh - #{$footer-height});
    background: linear-gradient(to bottom, $primary 400px, $ui4 400px);
  }
}

footer {
  background: $ui5;
  min-height: $footer-height;
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.1rem;
  .footer-group {
    margin: 0 1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex: 0 1 auto;
    &.icons {
      flex-wrap: nowrap;
    }
  }
  .footer-item {
    margin: 0 0.5rem;
    white-space: nowrap;
    &.icon {
      margin: 0 3rem 0 0;
    }
    img {
      display: inline-block;
      vertical-align: middle;
    }
  }
  &.login-footer {
    margin-top: 0;
    padding: 1rem;
  }
  .links {
    margin: 0.6rem 0;
  }
}

.title {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  text-align: left;
  color: $body3;
}

.body {
  font-size: 12px;
  line-height: 1.5;
  color: $body3;
}

.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 14.8rem;
  .heading-title {
    color: $light;
    line-height: 0.75;
  }

  &.create-account {
    padding-top: 3rem;
  }
}

.success-icon {
  height: 12px;
  width: 12px;
  background: url(../assets/check.svg) center center no-repeat;
  background-size: contain;
  margin-right: 5px;
}

.home {
  text-align: center;
  width: 541px;
  min-height: 453px;
  background-color: $light;
  padding: 4rem 6rem 6rem 6rem;
  margin-top: 3rem;
  position: relative;

  .informational-heading {
    padding: 1rem 3rem 1rem 3rem;
    font-size: 1.6rem;
  }

  .error-message {
    height: 4rem;
    width: 541px;
    background-color: $error1;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: $light;
    font-weight: bold;
    &.success-message {
      background-color: $success1;
    }
    .alert-icon {
      height: 12px;
      width: 12px;
      background: url(../assets/alert.svg) center center no-repeat;
      background-size: contain;
      margin-right: 5px;
    }
    .resend-verify {
      text-decoration: underline;
      font-size: 12px;
      padding-left: 1rem;
      cursor: pointer;
      font-weight: 600;
    }
  }
  input,
  button {
    width: 100%;
  }
  .login {
    margin: 2rem 0 3rem 0;
  }
  .forgot-password {
    margin: 3rem 0;
    font-size: 12px;
    font-weight: bold;
    color: $primary4;
    text-decoration: underline;
  }
  .line {
    line-height: 0.5;
    text-align: center;
    margin: 3rem 0 2rem 0;
    font-size: 14px;
  }
  .line span {
    display: inline-block;
    position: relative;
    color: $body3;
  }
  .line span:before,
  .line span:after {
    content: '';
    position: absolute;
    border-bottom: 1px solid $body3;
    bottom: 2px;
    width: 185px;
  }
  .line span:before {
    right: 100%;
    margin-right: 10.5px;
  }
  .line span:after {
    left: 100%;
    margin-left: 10.5px;
  }
}

.create-account.edit {
  padding-top: 0;

  form {
    grid-template-columns: repeat(4, 1fr);

    .form-field:nth-child(1) {
      grid-column-end: span 2;
    }

    .edit-btns {
      grid-column-start: span 4;
      border-top: 2px solid $ui3;
      margin-top: 4rem;
      text-align: left;
      width: 100%;
      padding-bottom: 1rem;
      button {
        width: 4rem;
      }
    }
  }

  /* START IE 11 fallback styles */
  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .form-field {
    width: 25%;
    padding-right: 2rem;
  }
  .form-field:nth-child(1) {
    width: 50%;
  }
  @supports (display:grid) {
    form {
      display: grid;
    }
    .form-field,
    .form-field:nth-child(1) {
      width: initial;
      padding-right: 0;
    }
  }
  /* END IE 11 fallback styles */

  .home {
    padding: 0;
    margin-top: 0;
    width: 100%;
    min-height: 0;

    .informational-heading {
      text-align: left;
      padding: 0;
    }
  }
}

.create-account form {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
  width: 100%;

  .secondary {
    margin-top: 3rem;
    grid-column-start: 1;
    grid-row-start: 9;
  }
  .submit {
    margin-top: 3rem;
    grid-column-start: 2;
    grid-row-start: 9;
  }
}

.create-account:not(.edit) form {
  .form-field:nth-of-type(1),
  .form-field:nth-of-type(4) {
    grid-column-end: span 2;
  }
}

.reset-password {
  &.wrapper {
    padding-top: 14.8rem;
  }
  padding-top: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 36rem;
  .informational-heading {
    padding-bottom: 2rem;
    width: 100%;
  }
  input {
    margin-bottom: 2rem;
  }
  .button-container {
    display: flex;
    align-items: center;
    button {
      width: 20rem;
      &.reset {
        margin-right: 0;
      }
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation-name: fade-in;
  animation-duration: 500ms;
  animation-timing-function: ease-in;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes green-fade {
  0% {
    background-color: white;
  }
  25% {
    background-color: rgba(96, 209, 136, 0.15);
  }
  80% {
    background-color: rgba(96, 209, 136, 0.15);
  }
  100% {
    background-color: white;
  }
}

.download-bom {
  color: $primary2;
  font-weight: bold;
  cursor: pointer;
  &::after {
    content: '';
    display: inline-block;
    width: 1.8rem;
    height: 1.2rem;
    background: url(../assets/download.svg) center center no-repeat;
    margin-left: 7px;
  }
}

.upload {
  color: $primary2;
  margin-right: 3rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0 0.5rem 0rem;
  width: auto;
  cursor: pointer;
  position: relative;
}
.upload:hover,
.upload:active {
  opacity: 0.37;
}
.upload::after {
  content: '';
  display: inline-block;
  width: 1.8rem;
  height: 1.2rem;
  background: url(../assets/upload.svg) center center no-repeat;
  margin-left: 7px;
}
#upload-file {
  width: 100%;
  cursor: pointer;
  position: absolute;
  opacity: 0;
}
.processing {
  &::after {
    transform: scale(1.4) rotate(0deg);
    animation: spin-scale 1500ms infinite linear;
    background: url('../assets/spinner.svg') no-repeat center center;
    background-size: contain;
  }
}

.project {
  #create-edit-project-form .form-field:first-of-type {
    width: 555px;
  }

  .lineBreak {
    border-bottom: 1px solid $ui3;
    margin: 40.5px 0 30.5px 0;
  }
  .quote-submitted,
  .simpull-truck-requested {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 600;
    color: $body4;
    margin-left: 3rem;
    &::before {
      content: '';
      display: inline-block;
      height: 1.2rem;
      width: 1.2rem;
      background: url(../assets/checkGreen.svg) center center no-repeat;
      background-size: contain;
      margin-right: 1rem;
    }
  }
  .header-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .btn-container {
      button {
        margin-left: 3rem;
      }
    }
  }

  td {
    .quote-submitted {
      margin-left: 0;
    }
  }
  .toggle-container {
    display: flex;
    align-items: center;
    margin-right: 2rem;
    .checkbox-toggle {
      label {
        background-color: $light;
      }
    }
    > span {
      margin-left: 1rem;
      font-size: 1.4rem;
      font-weight: 600;
      color: $body3;
    }
  }
}
.job-details {
  display: flex;
  width: 100%;
}
.job,
.reel {
  .body {
    display: flex;
    padding-top: 5px;
    p {
      font-size: 12px;
      width: 36.5rem;
    }
  }
  .inputs {
    display: flex;
    margin-bottom: 4.3rem;
    .form-field {
      margin-bottom: 0;
      margin-right: 3rem;
    }
  }

  .settings {
    display: flex;
    margin-bottom: 4.3rem;
    div {
      margin-right: 6rem;
    }
  }

  .restrictions {
    &.duplicate {
      margin: 6rem 0 2rem 0;
    }
  }

  .checkbox-toggle {
    width: 33.8rem;
    .toggle {
      vertical-align: middle;
      display: flex;
      text-align: center;
      border: 1px solid black;
      border-radius: 0.4rem;
      padding: 0.2rem;
      position: relative;
      width: 200px;
      span {
        flex: 1;
        text-align: center;
        border: 0px solid;
        padding: 0.5rem;
        border-radius: 0.3rem;
        height: 15px;
      }
    }
    input:checked + .toggle::after {
      transform: translate3d(-100%, 0, 0);
    }
    .toggle::after {
      position: absolute;
      top: 0.2rem;
      right: 0.2rem;
      left: 50%;
      bottom: 0.2rem;
      background: #29aae1;
      content: '';
      z-index: -1;
      border-radius: 0.4rem;
      transition: transform 0.2s cubic-bezier(0.4, -0.25, 0.25, 1);
    }
  }
}

table .no-column-value {
  color: $body4;
  font-weight: normal;
}

$overview-border: 1px solid $ui2;
$overview-radius: 2px;

.overview-list {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  td,
  th {
    padding: 1rem 0 1rem 2rem;
    text-align: left;
    font-weight: 500;
    color: $body2;
    font-size: 1.4rem;
    line-height: 1.25;

    &:last-child {
      text-align: right;
    }
  }
  .overflow-cell {
    padding: 0;
  }

  thead th {
    color: $body2;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;

    &:first-child {
      width: 30rem;
    }
    &:nth-child(2) {
      width: 10.5rem;
    }
    &:nth-child(3) {
      width: 10.5rem;
    }
  }
  tbody th,
  tbody td {
    height: 5.2rem;
    border-top: $overview-border;
    font-weight: 600;
  }
  tbody th {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  > tbody > tr {
    th:first-child,
    td:first-child {
      border-left: $overview-border;
    }
    &:first-of-type {
      th:first-of-type {
        border-top-left-radius: $overview-radius;
      }
      td:last-of-type {
        border-top-right-radius: $overview-radius;
      }
    }

    td:last-of-type {
      border-right: $overview-border;
    }
    &:last-of-type {
      > th,
      > td {
        border-bottom: $overview-border;
      }

      th:first-of-type {
        border-bottom-left-radius: $overview-radius;
      }
      td:last-of-type {
        border-bottom-right-radius: $overview-radius;
      }
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  &.many-columns {
    thead th {
      &:first-child {
        width: 27.5%;
      }
      &:nth-child(4) {
        width: 7rem;
      }
      &:nth-child(5) {
        width: 7rem;
      }
      &:nth-child(6) {
        width: 10rem;
      }
    }
  }
  .expand-cell {
    width: 4rem;
    padding: 0;
  }
}

.detail-section-title {
  font-size: 1.6rem;
  font-weight: 700;
  color: $body3;
}

.simpull-text {
  transform: translateY(-2px);
}

.reel-list-summary {
  td.expanded-item {
    padding: 0;
    background-color: $ui5;
    line-height: normal;
    height: auto;
    min-height: 8rem;

    .expanded-colors {
      padding: 2rem 2rem 2.5rem 2rem;
      display: flex;
      flex-wrap: wrap;
      .selected-color {
        margin-bottom: 1rem;
      }
    }

    .expanded-header > span:not(.simpull-text) {
      margin: 0 2rem 1rem 0;
    }
  }

  .reel-summary-item-expanded {
    > td {
      border-left: $overview-border;
      background: $ui5;
      padding: 0;
      .reel-summary-item-details {
        min-height: 28rem;
        display: flex;
        align-items: stretch;

        .reel-summary-circuits {
          width: 46rem;
          border-right: $overview-border;

          .empty-configure-section {
            padding-top: 3.9rem;
            border: 0;
          }

          th {
            width: 12rem;
            height: 5.2rem;
            border-top: none;
            border-left: none;
            border-right: $overview-border;
            border-bottom: $overview-border;

            &:nth-of-type(3) {
              width: 8rem;
            }
            &:nth-of-type(4) {
              width: 10rem;
              border-right: none;
            }
            &:nth-of-type(5) {
              width: 4rem;
              border-right: none;
            }
          }
          td {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            border-top: none;
            border-bottom: $overview-border;
            &:first-of-type {
              border-left: none;
            }
            &:last-of-type {
              border-right: $overview-border;
            }
          }
        }
        .reel-summary-visualization {
          background-color: $light;
          text-align: center;
          padding: 2rem;
          flex: 1;
          .detail-section-title {
            text-align: left;
            margin-bottom: 1rem;
          }

          .reel-bullseye {
            max-height: 21rem;
            max-width: 21rem;
          }
        }
      }
    }
  }
}

.reel-list {
  width: 31.4rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 1.5rem;
  overflow: hidden;
  .reel-title {
    border-top: $feeder-border;
    border-right: $feeder-border;
    border-left: $feeder-border;
    min-height: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: content-box;
    .reel-title-text,
    button {
      margin: auto 1rem auto 1.6rem;
    }
    .reel-title-text {
      color: $body3;
      font-size: 1.6rem;
      font-weight: 700;
    }
  }
  .reel-item-container {
    overflow-y: auto;
    overflow-x: hidden;
    border: $feeder-border;
    height: 100%;
  }
  .reel-header {
    height: 5rem;
    display: flex;
    border-bottom: $feeder-border;
    .reel-header-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &:first-of-type {
        flex: 1;
      }
      &:last-of-type {
        justify-content: flex-end;
        border-left: $header-border;
      }
    }
    .reel-header-text {
      padding: 0 1.6rem;
      font-size: 1.2rem;
      font-weight: bold;
      color: $body3;
      text-transform: uppercase;
    }
  }
  .empty-configure-section {
    background-color: $ui5;
  }
}

.reel-list-item-container {
  border-bottom: $feeder-border;
  background-color: $ui5;
  user-select: none;

  &.selected {
    background-color: $light;
    background-image: linear-gradient(to right, $primary2 5px, $light 5px);
  }
  &.selectedExceedsThreshold {
    background-image: linear-gradient(to right, $warning 5px, $light 5px);
  }
  &:last-of-type {
    margin-bottom: -1px;
  }
  .item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 0 1.6rem;
    padding: 1.6rem 0 2rem 0;
    font-size: 1.4rem;
    font-weight: 600;
    .overflow {
      margin: 0 1rem 0.8rem;
      height: 2rem;
      width: 2rem;
      &:after {
        right: 0;
      }
      background-position: center;
      .options {
        top: 25px;
        right: 20px;
      }
    }
    .header-left {
      max-width: 20.5rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .header-right {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .capacity {
    border-top: 1px solid $ui4;
  }
  .item-body,
  .capacity {
    margin: 0 1.6rem 1.6rem 1.6rem;
    .header-left {
      margin: 1rem 0 1rem 0;
      font-size: 1.4rem;
      font-weight: 600;
    }
    .info-row {
      font-size: 1.2rem;
      color: $body5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .dimensions {
        display: flex;
        flex-direction: row;
        .height {
          border-right: 1px solid $ui4;
          margin-right: 1rem;
          padding-right: 1rem;
        }
      }
      &:first-of-type {
        margin: 0.8rem 0;
      }
      .right-side {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .info-message {
      font-size: 1.2rem;
      color: $body5;
      display: flex;
      padding-left: 24px;
      background: url(../assets/information.svg) left center no-repeat;
      background-size: 20px 20px;
    }
  }
}

.reel-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-left: 0.6rem;
  margin-bottom: 1.3rem;
  .background-bar {
    height: 4px;
    border-radius: 2px;
    background-color: $ui5;
    position: relative;
    width: 15rem;
    .front-bar {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      border-radius: 2px;
      width: 0%;
      background-color: $success2;
    }
  }
}

.empty-configure-section {
  flex: 1;
  border-radius: 2px;
  border: $feeder-border;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg,
  img {
    min-width: 8.4rem;
  }

  .empty-message {
    font-size: 1.6rem;
    line-height: 1.5;
    padding: 3.9rem 2rem;
  }
}
.reel-bullseye {
  max-width: 33rem;
  max-height: 33rem;
  width: 100%;
  flex: 1;
}
.reel-capacity-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.reel-graph-container {
  margin-top: 1rem;
  width: 40%;
  max-width: 13rem;

  .label {
    font-size: 1.6rem;
    color: $body3;
    font-weight: 700;
    text-align: center;
  }

  .display {
    font-weight: 300;
    font-size: 2rem;
    position: absolute;
    width: 100%;

    top: 50%;
    transform: translateY(-50%);
    left: 0;
    text-align: center;
    z-index: 1;

    @media all and (min-width: 68em) {
      font-size: 2.8rem;
    }
    @media all and (min-width: 76em) {
      font-size: 3.4rem;
    }
  }
}
.reel-graph {
  position: relative;
  text-align: center;
}
.reel-graph svg {
  width: 100%;
  transform: rotate(-90deg);
}
.reel-graph .progress {
  stroke-dasharray: 299px; //this is the circumference of our circle graph
  stroke-dashoffset: 299px;
  transition: stroke-dashoffset 290ms ease-out;
}
.reel-name-display {
  font-size: 1.6rem;
  font-weight: bold;
  color: $body1;
  align-self: flex-start;
  transform: translateX(-2rem);
  width: calc(100% + 2rem);
  text-overflow: ellipsis;
}
.reel-package-display {
  font-size: 2.8rem;
  font-weight: 300;
  color: $body1;
  align-self: flex-start;
  transform: translateX(-2rem);
  margin-bottom: -2rem;
}

.feeder-schedule {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .section-toggle {
    font-weight: bold;
    text-align: center;
    background-color: $ui5;
    cursor: pointer;
    height: 6rem;

    .toggle {
      color: $body3;
      width: 46rem;
      display: flex;
      border-left: $header-border;
      border-right: $header-border;
      div {
        width: 23rem;
        height: 5.9rem;
        padding-top: 2rem;
        opacity: 0.4;
      }
      .selected {
        background-color: $light;
        opacity: 1;
      }
    }
  }
  .table-wrapper {
    width: 100%;
    flex: 1;
    overflow-y: hidden;
    .overview-list {
      height: 100%;
      width: 100%;
      display: block;
      table-layout: fixed;
      position: relative;
      border-collapse: collapse;

      &.circuits-on-reel {
        thead {
          th {
            cursor: auto;
            div::after {
              display: none;
              content: '';
            }
          }
        }
      }

      thead,
      tfoot,
      tbody > tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }
      thead {
        position: absolute;
        top: 0;
        left: 0;
        border: $feeder-border;
        height: 5rem;
        background-color: $light;
        z-index: 1;

        th {
          color: $body3;
          text-transform: uppercase;
          font-size: 1.2rem;
          font-weight: 600;
          padding-left: 1.4rem;
          height: 5rem;
          cursor: pointer;
          &:first-child {
            width: 9rem;
            border-right: $header-border;
          }
          &:nth-child(2) {
            width: 9rem;
            border-right: $header-border;
          }
          &:nth-child(3) {
            width: 7.6rem;
            border-right: $header-border;
          }
          &:nth-child(4) {
            width: 9rem;
          }
          &:nth-child(5)::after,
          &:nth-child(6)::after,
          &:nth-child(7)::after {
            display: none;
          }
          div::after {
            content: '';
            display: inline-block;
            position: absolute;
            width: 1.2rem;
            height: 1.3rem;
            background: url(../assets/sort-ascending.svg) center center
              no-repeat;
            margin-left: 1rem;
            opacity: 0.4;
          }
          &.selected-sort {
            background-color: $ui5;
            div::after {
              opacity: 1;
            }
          }
        }
      }
      tbody tr {
        td:last-of-type {
          border-right: 0;
        }
      }
      tbody {
        display: block;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        border: $feeder-border;
        transition: opacity 280ms linear;
        user-select: none;
        padding-top: 5rem;
        height: 100%;

        tr:first-of-type {
          td {
            border-top: 0;
          }
        }
        tr {
          height: 5rem;
          position: relative;

          td {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding-left: 1.4rem;
            &:first-child {
              width: 9rem;
              border-left: none;
            }
            &:nth-child(2) {
              width: 9rem;
            }
            &:nth-child(3) {
              width: 7.6rem;
            }
            &:nth-child(4) {
              width: 8.4rem;
            }
          }

          .overflow-cell {
            overflow: visible;
            width: 4rem;
            padding: 0;
            .overflow {
              width: 4rem;
              background-position: 0.4rem center;
              .options {
                right: 20px;
              }
            }
          }
          .expand-cell,
          .add-cell,
          .remove-cell,
          .reorder-cell {
            width: 4rem;
            padding: 0;
          }

          .reorder-cell {
            background: url(../assets/grabber.svg) no-repeat;
            background-position: center 50%;
            background-size: 24px 24px;
            cursor: row-resize;
          }

          &.circuit-warning {
            &::after {
              content: 'Incompatible';
              position: absolute;
              top: 0.1rem;
              right: 8rem;
              bottom: 0.1rem;
              width: 12rem;
              padding: 0 1rem 0.2rem 0;
              font-size: 1.1rem;
              text-align: right;
              background: url(../assets/alert-red.svg) right 1rem top 1.7rem
                  no-repeat,
                linear-gradient(to right, rgba(255, 255, 255, 0) 50%, #fff 50%);
              background-size: 1.8rem 1.5rem, auto auto;
              display: flex;
              justify-content: flex-end;
              align-items: flex-end;
            }
            &.exceeds::after {
              content: 'Exceeds Capacity';
            }
          }
        }
        .expanded-row {
          height: 13rem;
          padding: 0;
          background-color: $ui5;
          overflow: visible;
          .notch {
            width: 16px;
            height: 16px;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            background-color: $ui5;
            border-top: $feeder-border;
            border-left: $feeder-border;
            position: relative;
            left: 392px;
            bottom: 9px;
          }
          .expanded-colors {
            padding: 2rem 2rem 2.5rem 2rem;
            display: flex;
            flex-wrap: wrap;
            .selected-color {
              margin-bottom: 1rem;
            }
          }
          .expanded-header {
            display: flex;
            flex-wrap: wrap;
            border-bottom: $feeder-border;
            padding: 2rem 0 2rem 0;
            margin: 0 2rem;

            & > span:not(.simpull-text) {
              margin: 0 2rem 1rem 0;
            }

            .checkbox-toggle {
              margin-left: 3rem;
            }
          }
        }
      }
    }
  }
}

.empty-container {
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img,
  svg {
    width: 100%;
    max-width: 72rem;
    max-height: 40rem;
    margin-bottom: 3rem;
  }
}

.important-message {
  font-size: 2.8rem;
  font-weight: 300;
  text-align: center;
}

.breadcrumbs {
  display: flex;
  justify-content: flex-start;

  a {
    display: inline-block;
    color: $primary2;
    padding: 0 0.75rem 0 0;
  }
  li {
    font-size: 1.2rem;
    font-weight: bold;
  }

  li::after {
    content: '';
    display: inline-block;
    width: 7px;
    height: 10px;
    background: url(/assets/img/breadcrumb-chevron.svg) center center no-repeat;
    margin-right: 0.75rem;
  }

  &.terminated li:last-child::after {
    content: initial;
    background: transparent;
  }
}

dl {
  display: flex;
  font-size: 1.2rem;
  font-weight: 600;
  dt {
    color: $body4;
  }
  dt::after {
    content: ':';
    padding-right: 0.75rem;
  }
  dd {
    color: $body1;
    margin-right: 3rem;
  }
}

.section-toggle {
  height: 8rem;
  background-color: $background1;
  width: 100%;
  display: flex;
  align-items: flex-end;
  border-top: solid 1px $ui3;
  justify-content: space-between;

  .toggle {
    a {
      font-weight: 600;
      color: $body3;
      padding: 0.5rem 1.5rem;
      margin-top: 2.6rem;
      height: 5.4rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background: $light;
      opacity: 0.5;
      &.selected {
        opacity: 1;
      }
      > span {
        padding-left: 1rem;
      }
    }
  }
  .section-links {
    display: flex;
    align-items: center;
    font-size: 1.6rem;

    .template-links {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      align-items: flex-end;
      a {
        color: $primary2;
        padding: 0.5rem 0rem 0.5rem 0rem;
        margin-right: 3rem;
        font-weight: 600;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        &::after {
          content: '';
          display: inline-block;
          width: 1.8rem;
          height: 1.2rem;
          background: url(../assets/download.svg) center center no-repeat;
          margin-left: 7px;
        }
      }
    }
  }
}
.job-summary .section-toggle {
  position: absolute;
  left: 0;
  right: 0;

  .container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    align-self: stretch;
  }
}

@media all and (max-width: 70em) {
  .job-summary {
    button,
    a,
    .section-links {
      font-size: 12px;
    }
  }
  .hoverable-text {
    p {
      font-size: 12px;
    }
  }
}

@media all and (min-width: 58em) {
  .section-toggle {
    height: 6rem;
    .toggle {
      a {
        margin-top: 0.6rem;
      }
    }
    .section-links {
      .template-links {
        flex-direction: row;
        justify-content: flex-end;
        align-items: stretch;
      }
    }
  }
}

.auto-build-notification {
  margin-bottom: 2rem;
  background-color: $primary2;
  color: $light;
  font-size: 1.4rem;
  min-height: 4rem;
  display: flex;
  border-radius: 2px;

  img {
    padding-left: 1rem;
  }

  span {
    margin: auto 0;
    padding-left: 1rem;
  }

  button {
    min-width: 80px;
    font-size: 1.2rem;
    background-color: transparent;
    margin-left: auto;
    margin-right: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.job-summary + * {
  margin-top: 8.7rem;
}

.configure-job {
  .secondary-title {
    padding-bottom: 0rem;
  }
}
.configure-sections {
  //Structure for the panes on the Configure Job screen,
  //will fill available space width-wise and height-wise
  //Will also need to be tweaked as we continue builing
  display: flex;
  justify-content: space-between;
  height: calc(100vh - #{($header-height + $configure-extra-space)});
  margin-top: 4rem;
  min-height: 42rem;
  width: 108.5%;
  transform: translateX(-4%);
  .feeder-schedule-section {
    width: 46rem;
  }
  > div {
    display: flex;
    flex-direction: column;
    position: relative;

    > button {
      align-self: flex-end;
    }
  }
  .reel-visualization {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 3.5rem $gutter 0;
    -webkit-overflow-scrolling: touch;

    > * {
      display: none;
      @media all and (min-width: 63em) {
        //1008px
        display: flex;
      }
    }
  }
}
.build-circuit {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1.5fr 1fr;
  grid-column-gap: 2rem;
  max-width: 74rem;
  width: 100%;

  input[type='text'],
  input[type='number'],
  select {
    width: 100%;
  }
  select:disabled {
    background-image: none;
  }
  .pulling-heads-toggle .checkbox-toggle {
    padding-top: 1rem;
    transform: translateY(1.5rem);
  }
  .pulling-heads-toggle {
    grid-column: 4/6;
  }
  &.edit-circuit {
    .pulling-heads-toggle {
      grid-column: 3/5;
    }
  }
  .metal-insulation {
    grid-column: 1/3;
  }
  .conductors-number {
    grid-column: 4/5;
  }
  .ground-fields {
    grid-column: 1/6;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1.5fr 1fr;
    grid-column-gap: 2rem;
  }
  .ground-metal {
    grid-column: 1/2;
  }
  .ground-size {
    grid-column: 2/4;
    .dropdown__menu-wrapper {
      .dropdown__menu {
        max-height: 18rem;
      }
    }
  }
  .ground-size + .checkbox-toggle {
    padding-top: 3.5rem;
  }
  .ground-error {
    grid-column: 1/6;
    color: $error1;
  }
  .checkbox {
    grid-column: 4/6;
    justify-self: end;
  }
  .ground-error-message {
    grid-column: 1/6;
    padding-bottom: 2rem;
    font-size: 1.2rem;
    font-weight: bold;
  }
  .action-buttons {
    grid-column: 1/6;
    padding-top: 2rem;
    display: grid;
    button {
      width: 21.5rem;
    }
  }
  .pickable-color {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
    width: 3.6rem;

    input,
    label {
      cursor: pointer;
      color: $body3;
      margin-bottom: 0;
      font-weight: 300;
      font-size: 1.1rem;
    }

    input {
      appearance: none;
      background: $light;
      border: 1px dotted $ui3;
      border-radius: 50%;
      width: 3.6rem;
      height: 3.6rem;
    }
    &.pickable-selected {
      input {
        border: 3px solid $selected;
      }
      label {
        font-weight: 700;
      }
    }
  }
  .pickable-colors {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 12rem;
    align-content: flex-start;

    .pickable-color {
      margin: 0 1.6rem 1rem 0;
      input {
        border-style: solid;
      }
    }
  }
  .color-sets {
    display: flex;
    flex-wrap: wrap;
  }
  .preset {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 2rem 2rem 0;

    input {
      cursor: pointer;
      width: 8.2rem;
      height: 5.6rem;
      border: 1px solid $ui3;
      border-radius: 2px;
    }
  }
  &.mv {
    grid-template-columns: repeat(5, 1fr);

    h3 {
      grid-column: 1/5;
      font-weight: 300;
      margin-bottom: 1.5rem;
    }

    .metal,
    .conductors-size,
    #simpull-toggle,
    .reel-name,
    #isAFrame-toggle,
    #reel-config,
    #ground-metal-insulation {
      grid-column: 1/3;
    }

    .voltage-thickness,
    .jacket,
    #ground-size {
      grid-column: 3/5;
    }

    .checkbox-toggle {
      margin: 2rem 0 1.5rem 0;
    }

    #reel-type {
      grid-column: 3/5;
    }

    .reel-config-table {
      grid-column: 1/5;
      color: $body3;
      font-size: 14px;
      border-collapse: collapse;

      td {
        padding: 0.8rem 1.5rem;
        border: 1px solid #d3d5db;
      }

      thead {
        background-color: #e9ebf0;
      }
      tbody {
        background-color: #f7f9fc;
      }

      thead td {
        font-weight: bold;
      }
    }
  }

  /* START IE 11 fallback styles */
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .conductor-builder {
    width: 100%;
    .size-picker {
      width: 30%;
      float: left;
      .form-field {
        width: 100%;
      }
    }
    .color-picker {
      width: 70%;
      float: left;
    }
  }
  .form-field {
    width: 25%;
    padding-right: 1em;
  }
  .metal-insulation {
    width: 50%;
  }
  .pulling-heads-toggle {
    width: 25%;
  }
  .ground-fields {
    width: 100%;
    .ground-size {
      float: left;
      width: 40%;
    }
    .ground-metal,
    .checkbox-toggle {
      float: left;
    }
  }
  .action-buttons {
    width: 100%;
    position: relative;
    .checkbox {
      position: absolute;
      top: 3rem;
      right: 0;
    }
  }

  &.mv {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .form-field {
      width: 50%;
      padding-right: 2em;
    }
    h3,
    .checkbox-toggle {
      width: 100%;
    }
  }
  @supports (display:grid) {
    display: grid;
    &.mv {
      display: grid;
    }
    .form-field,
    &.mv .form-field,
    .pulling-heads-toggle {
      width: initial;
      padding-right: 0;
    }
    .conductor-builder .size-picker,
    .conductor-builder .color-picker,
    .ground-fields .ground-metal,
    .ground-fields .ground-size,
    .ground-fields .checkbox-toggle {
      width: initial;
      float: none;
    }
    .action-buttons .checkbox {
      position: relative;
      top: auto;
      right: auto;
    }
  }
  /* END IE 11 fallback styles */
}

.field-alert {
  background: url(../assets/alert-red.svg) left 1rem no-repeat;
  background-size: 14px 14px;
  padding-left: 2rem;
  padding-top: 0.8rem;
  line-height: 1.5;
  font-size: 1.2rem;
  font-weight: bold;
  opacity: 0;
  transition: opacity 175ms linear;

  &.alerted {
    opacity: 1;
    &.delay-alert {
      transition-delay: 1000ms;
    }
  }
}

.field-warning {
  background: url(../assets/information.svg) left top no-repeat;
  background-size: 22px 22px;
  padding-left: 2.6rem;
  padding-top: 0.2rem;
}

.action-buttons {
  border-top: 0.1rem solid $ui3;
  padding-top: 3rem;
}

.edit-account {
  .content-top {
    margin-bottom: 3rem;
  }
  .page-title {
    padding-bottom: 3rem;
  }
  .title {
    color: $body1;
    padding-bottom: 1rem;
  }
  .email {
    padding-bottom: 2rem;
    font-size: 1.4rem;
  }
  .logout {
    cursor: pointer;
    color: $primary4;
    font-weight: bold;
    margin-bottom: 3rem;
  }
}

.toast-message {
  position: fixed;
  top: -30px;
  right: 0;
  left: 0;
  text-align: left;
  background: $success1;
  &.loggedIn {
    .container {
      display: flex;
      align-items: center;
      padding: 0.6rem 1rem 0.6rem $gutter;
      text-align: left;
      .toast-icon {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
    &[class*='error'] {
      background-color: $error1;
      font-weight: bold;
    }
  }
  .container {
    padding: 0.6rem 1rem 0.6rem calc(2.4rem + #{$gutter});
    text-align: center;
    display: flex;
    justify-content: center;
  }
  color: $light;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 1.3;

  opacity: 0;
  will-change: opacity;

  &.toasting {
    animation: toast 5000ms ease-in-out;
    top: 0;
  }

  &[class*='error'] {
    background-color: $error1;
    font-weight: bold;
  }

  &[class*='error'],
  &[class*='success'] {
    transition: opacity 200ms ease-out;
  }

  &.persist {
    opacity: 1;
    top: 0;
  }

  .toast-text {
    margin-left: 5px;
  }
}

.alert-icon {
  height: 12px;
  width: 12px;
  background: url(../assets/alert.svg) center center no-repeat;
  background-size: contain;
  margin-right: 12.5px;
  display: inline-block;
}

.check-icon {
  height: 12px;
  width: 12px;
  background: url(../assets/check.svg) center center no-repeat;
  background-size: contain;
  margin-right: 12.5px;
  display: inline-block;
}

.exit-icon {
  height: 1rem;
  width: 1rem;
  margin-left: 40px;
  background: url(../assets/x.svg) center center no-repeat;
  cursor: pointer;
  display: inline-block;
}

.resend-link {
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}

@keyframes toast {
  10%,
  50%,
  90% {
    opacity: 1;
  }
}

.edit-reel-container {
  .edit-reel {
    max-width: 56rem;
    .form-field {
      margin-bottom: 6rem;
    }
  }
  .column-container {
    margin-top: 2rem;
    margin-bottom: 4rem;
    .column {
      display: inline-block;
      min-width: 10rem;
      font-size: 1.4rem;
      .heading {
        font-weight: 600;
        margin-bottom: 1rem;
      }
    }
  }
  .edit-btns {
    margin-top: 3rem;
  }
}

.viewers {
  .lb {
    border-bottom: 1px solid $ui3;
    margin-bottom: 3rem;
  }
  .page-title {
    padding-bottom: 3rem;
  }
  .viewers-container {
    width: 50%;
    min-width: 60rem;
    textarea {
      height: 9rem;
      width: 100%;
      padding: 1rem 1.6rem;
      font-size: 1.4rem;
      font-family: 'Open Sans';
      border-radius: 2px;
      &:not(.error-border) {
        border: solid 1px $ui7;
      }
    }
    .instructions {
      margin-bottom: 3rem;
    }
    .textarea-label {
      font-size: 1.2rem;
      color: $body5;
      padding-top: 8px;
      margin-bottom: 6rem;
    }
  }
}

//FIXME Need to duplicate styles here for reorder, ultimately need to refactor
//the tables app-wide and simplify
//For now, any changes to the feeder-schedule row needs to be accounted for here, too

.reordering-item {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.reordering-item {
  td:last-of-type {
    border-right: 0;
  }
}
.reordering-item {
  height: 5rem;
  max-height: 5rem;
  position: relative;
  border: 1px solid $ui3;
  background: white;
  overflow: hidden;
  user-select: none;

  td {
    height: 5rem;
    padding: 1rem 0 1rem 2rem;
    text-align: left;
    vertical-align: middle;
    font-weight: 500;
    color: $body2;
    font-size: 1.4rem;
    line-height: 1.25;
  }
  .overflow-cell {
    padding: 0;
  }

  td {
    font-weight: 600;
  }
  tbody th {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  td {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 1.4rem;
    &:first-child {
      width: 9rem;
    }
    &:nth-child(2) {
      width: 9rem;
    }
    &:nth-child(3) {
      width: 7.6rem;
    }
    &:nth-child(4) {
      width: 8.4rem;
    }
  }

  .overflow-cell {
    overflow: visible;
    width: 4rem;
    padding: 0;
    .overflow {
      width: 4rem;
      background-position: 1rem center;
    }
  }
  .expand-cell,
  .add-cell,
  .remove-cell,
  .reorder-cell {
    width: 4rem;
    padding: 0;
  }
  .remove-cell {
    width: 8rem;
    opacity: 0;
  }
  .expand-cell {
    display: none;
  }

  .reorder-cell {
    background: url(../assets/grabber.svg) no-repeat;
    background-position: center 50%;
    background-size: 24px 24px;
    cursor: row-resize;
  }
}

.drag-handle {
  background: url(../assets/grabber.svg) no-repeat;
  background-position: center 50%;
  background-size: 24px 24px;
  cursor: row-resize;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.currently-reordering {
  opacity: 0.6;
}

@keyframes spin-scale {
  100% {
    transform: scale(1.4) rotate(360deg);
  }
}

.edit-circuits-modal {
  &#modal {
    width: 50rem;

    .modal-text {
      text-align: left;
      padding: 2.1rem 3rem;
    }

    .button-container .confirm {
      color: $primary2;
    }
  }

  .modal-text {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;

    input {
      width: 100%;
    }

    .form-field label {
      min-height: 22px;
    }

    .length-warning {
      grid-column: 1/3;
      font-size: 0.8em;
      margin-bottom: 2rem;
    }

    .message {
      grid-column: 1/3;
      font-size: 0.8em;
      padding-left: 24px;
      margin-top: 1rem;
    }

    .info-message {
      background: url(../assets/information.svg) left top no-repeat;
      background-size: 20px 20px;
    }

    .error-warning {
      background: url(../assets/alert-red.svg) left top no-repeat;
      background-size: 18px 18px;
    }

    .warning-message {
      background: url(../assets/warning.svg) left top no-repeat;
      background-size: 18px 18px;
    }
  }
}

.hoverable-text {
  font-size: 12px;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
