input {
  height: 40px;
  border-radius: 2px;
  background-color: #ffffff;
  border: solid 1px $ui7;
  padding-left: 16px;
  font-size: 14px;
  width: 100%;
}

.form-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.error-border {
  border: solid 1px #f33a0e; 
}

input:focus {
  outline: 0;
  border: solid 1px #376bad;
}
input:invalid { //cancel out firefox styling
  outline: 0;
  box-shadow: none;
}

label, .label {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.25;
  color: #1a1818;
  margin-bottom: 10px;
  text-align: left;

  &.required::after {
    content: '*';
  }
}

.field-messages {
  position: relative;

  .field-alert {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    min-height: 2em;
    background-position-y: 1rem;
  }
}

.disclaimer {
  font-size: 12px;
	line-height: 1.5;
  padding-top: 8px;
	color: #68727d;
  display: -webkit-box;
}

.info {
  height: 22px;
  width: 22px;
  background: url(../assets/information.svg) center center no-repeat;
  background-size: contain;
  margin-right: 5px;
}

.warning {
  height: 16px;
  width: 16px;
  background: url(../assets/warning.svg) center center no-repeat;
  background-size: contain;
  margin-right: 8px;
}

