.icon-button {
  width: 4rem;
  height: 4rem;
  min-width: 0;
  display: flex;
  overflow: hidden;
  border-radius: 50%;

  &:disabled {
    cursor: initial;
  }

  img {
    margin: auto;
    width: 1.8rem;
  }

  span {
    transform: translateY(-200%);
  }

  &.expanded,
  &.collapse {
    margin: 0 0 0 auto;
    width: 24px;
    height: 24px;
  }

  &.duplicate {
    background: transparent url(../assets/duplicate.svg) center center no-repeat;
  }
  &.delete {
    background: transparent url(../assets/delete.svg) center center no-repeat;
  }
  &.single-edit {
    background: transparent url(../assets/single-edit.svg) center center no-repeat;
  }
  &.mass-edit {
    background: transparent url(../assets/mass-edit.svg) center center no-repeat;
  }
  &.add-to-reel {
    background: transparent url(../assets/create.svg) center center no-repeat;
  }
  &.remove-from-reel {
    background: transparent url(../assets/remove.svg) center center no-repeat;
  }
  &.expanded {
    background: transparent url(../assets/expand.svg) center center no-repeat;
  }
  &.collapse {
    background: transparent url(../assets/collapse.svg) center center no-repeat;
  }
  &.auto-build {
    background: transparent url(../assets/auto-build.svg) center center no-repeat;
  }
}
.icon-button-container {
  position: relative;
  display: flex;
}