.checkbox-toggle {
  input {
    position: absolute;
    opacity: 0;
    width: 40px;
    height: 22px;
    z-index: 5;
    cursor: pointer;
  }
  label {
    width: 40px;
    height: 22px;
    vertical-align: middle;
    display: flex;
    text-align: center;
    border: 2px solid $ui7;
    border-radius: 11px;
    padding: .2rem;
    margin-bottom: 0;
    position: relative;
    cursor: pointer;
  }
  .name {
    position: relative;
    bottom: 22px;
    left: 50px;
    font-size: 14px;
    font-weight: 600;
    color: #68727d;
  }
  .disclaimer {
    position: relative;
    bottom: 22px;
  }
  input:checked + label::after {
    transform: translate3d(17px, 0, 0);
    background: $success1;
  }
  input:checked + label {
    border-color: $success1;
  }
  label::after {
    position: absolute;
    top: .3rem;
    left: .3rem;
    bottom: .2rem;
    height: 12px;
    width: 12px;
    background: $ui7;
    content: '';
    z-index: 0;
    border-radius: 50%;
    transition: transform 0.2s cubic-bezier(0.4, -0.25, 0.25, 1);
  }

  input:disabled + label,
  input:disabled + label + .name {
    opacity: .37;
  }
}
